.notFound {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: url('https://source.unsplash.com/random/1280x1024');
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: darken;
  background-repeat: none;
  height: 100%;
  width: 100%;
  color: #fff;
}

.notFound > .Particles > canvas {
  position: absolute;
}

.notFound > .copy {
  padding: 16px;
  /* text-align: center;
    position: absolute;
    top: 5%;
    left: 10%;
    right: 10%; */
}

.notFound > .copy > button {
  margin-top: 10px;
}

.notFound > .copy > h1,
.notFound > .copy > h4 {
  color: white;
}
