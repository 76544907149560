body,
html {
  /* height: 100%; */
  margin: 0;
  padding: 0;
  /* background: #27225b; */
  display: flex;
  flex-grow: 1;
  height: 100%;
}

#root {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

pre {
  word-break: break-all;
}
